
import FormTextInput from '@/components/common/FormTextInput.vue';
import EditDefaultLevelsModal from '@/components/modal/EditDefaultLevelsModal.vue';
import { storeState } from '@/store/Globals';
import { FuelCustomSettingsData } from '@/store/models/CustomSettingsData';
import CustomStore from '@/store/modules/CustomStore';
import { isAdmin } from '@/utils/TestingValidity';
import { mixins } from 'vue-class-component';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: {
    FormTextInput,
    EditDefaultLevelsModal
  },
  methods: {
    isAdmin
  }
})
export default class AlertsSettings extends mixins(AppName, ConstantsMixin) {
  @Prop() isTable!: boolean;
  @Prop() width!: number;
  public lowLevelDefault = 30;
  public criticalLevelDefault = 10;

  get storeLoadingState() {
    return storeState.loading;
  }

  @Watch('storeLoadingState')
  storeStateUpdated() {
    if (!this.storeLoadingState) {
      this.updateDefaults();
    }
  }

  mounted() {
    if (!this.storeLoadingState) {
      this.updateDefaults();
    }
  }

  public updateDefaults() {
    this.lowLevelDefault =
      (CustomStore.customEntitySettings as FuelCustomSettingsData)
        ?.lowLevelThreshold ?? 0;
    this.criticalLevelDefault =
      (CustomStore.customEntitySettings as FuelCustomSettingsData)
        ?.criticalLevelThreshold ?? 0;
  }

  public openLowLevelModal() {
    this.$root.$emit('bv::show::modal', `editDefaultLowLevelModal`, '#btnShow');
  }
  public openCriticalLevelModal() {
    this.$root.$emit(
      'bv::show::modal',
      `editDefaultCriticalLevelModal`,
      '#btnShow'
    );
  }
}
