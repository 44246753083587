
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import CurrentUser from '@/store/models/CurrentUser';
import FormTextInput from '@/components/common/FormTextInput.vue';
import FormDropdownInput from '@/components/common/FormDropdownInput.vue';
import User from '@/store/modules/User';
import { UpdatePartnerUsers } from '@/services/api/UsersApi';
import { mapGetters } from 'vuex';
import { trackEvent } from '@/services/Mixpanel';
import { mixins } from 'vue-class-component';
import AppName from '@/components/mixin/AppName.vue';
import PhoneNumberInput from '@/components/items/PhoneNumberInput.vue';
import UsersCardList from '../list/UsersCardList.vue';

@Component({
  components: {
    FormTextInput,
    FormDropdownInput,
    PhoneNumberInput,
    UsersCardList
  },
  computed: {
    ...mapGetters('user', {
      currentUser: 'currentUser'
    })
  }
})
export default class UserList extends mixins(AppName) {
  @Prop() users!: CurrentUser[]; // For now this is true for type, but we might want a new abstract interface for table items.
  @Prop() isTable!: boolean;
  @Prop() loading!: boolean;
  @Prop() searchTerm!: string;
  @Prop() role!: string;

  public active = -1;
  public topPosition = 553;
  public selectedUser = 0;
  public submitted = false;
  public unresolvableError = false;

  public editUserField = {
    name: '',
    email: '',
    phone: '',
    role: ''
  };
  public colour = '#F6861F';
  public bootstrapColour = 'secondary';

  created() {
    window.addEventListener('resize', this.getPosOfListView);
    document.addEventListener('mouseup', this.getPosOfListView);
  }
  destroyed() {
    window.removeEventListener('resize', this.getPosOfListView);
    document.removeEventListener('mouseup', this.getPosOfListView);
  }

  mounted() {
    this.getPosOfListView();
    this.colour = this.getAppColour(false);
    this.bootstrapColour = this.getAppColour(true);
  }

  @Emit('search')
  public search(searchTerm: string) {
    return searchTerm;
  }

  @Emit('updateRoleFilter')
  public updateRoleFilter(role: string) {
    return role;
  }

  @Emit('addNewUser')
  public addNewUser() {
    return;
  }

  public getPosOfListView() {
    if (this.$refs['listUserContainer'] as HTMLElement) {
      this.topPosition =
        (this.$refs['listUserContainer'] as HTMLElement).getBoundingClientRect()
          .top - 100;
    }
  }

  public getTopPos() {
    return `max-height:calc(100% - ${this.topPosition}px);`;
  }

  public editUser(index: number) {
    this.selectedUser = index;
    this.$root.$emit('bv::show::modal', 'editUserData', '#btnShow');
  }

  public deleteUser(index: number) {
    this.selectedUser = index;
    this.$root.$emit('bv::show::modal', 'deleteUserModal', '#btnShow');
  }

  public async onSubmit() {
    this.closeModal();
    const changedFields: {
      name?: string;
      email?: string;
      phone?: string;
      role?: string;
    } = {};
    if (
      this.editUserField.name != this.users[this.selectedUser].name &&
      this.editUserField.name != ''
    ) {
      changedFields.name = this.editUserField.name;
    }
    if (
      this.editUserField.email != this.users[this.selectedUser].email &&
      this.editUserField.email != ''
    ) {
      changedFields.email = this.editUserField.email;
    }
    if (
      this.editUserField.phone != null &&
      this.editUserField.phone != this.users[this.selectedUser].phone
    ) {
      changedFields.phone = this.editUserField.phone;
    }
    if (
      this.editUserField.role != this.users[this.selectedUser].role &&
      this.editUserField.role != ''
    ) {
      changedFields.role =
        this.editUserField.role == 'Admin' ? 'partner.admin' : 'partner.user';
    } else {
      changedFields.role =
        this.users[this.selectedUser].role == 'Admin'
          ? 'partner.admin'
          : 'partner.user';
    }
    const params = {
      ...this.users[this.selectedUser],
      ...changedFields
    }; // makes every user admin curently only admin users

    await UpdatePartnerUsers({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      userId: this.users[this.selectedUser].id ?? -1,
      params: params
    }).then(response => {
      if (response == -1) {
        this.unresolvableError = true;
      }
      this.submitted = true;
      trackEvent('User edited user');

      this.$root.$emit('usersUpdated');
      if (this.submitted && this.editUserField.email != '') {
        this.$bvToast.toast(
          `${
            this.users[this.selectedUser].name
          } will need to check their email mailbox for ${
            changedFields.email
          } and verify their new email address`,
          {
            title: 'Email change verification sent!',
            toaster: 'b-toaster-bottom-center',
            solid: true,
            append: false
          }
        );
      }
      User.fetchCurrentUser();
      this.$bvToast.toast(
        this.unresolvableError
          ? `${
              this.users[this.selectedUser].name
            }'s details have not been updated`
          : `${this.users[this.selectedUser].name}'s details have been updated`,
        {
          title: this.unresolvableError
            ? 'Details Not Updated'
            : 'Details Updated',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          append: false
        }
      );
    });

    this.editUserField = {
      name: '',
      email: '',
      phone: '',
      role: ''
    };
  }

  public async confirmDeleteUser() {
    this.users[this.selectedUser].role = 'Deleting This User';
    delete this.users[this.selectedUser].roleId;

    await UpdatePartnerUsers({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      userId: this.users[this.selectedUser].id ?? -1,
      params: { ...this.users[this.selectedUser] }
    }).then(response => {
      if (response == -1) {
        this.unresolvableError = true;
      }
      this.submitted = true;
      trackEvent(`User deleted ${this.users[this.selectedUser].name}`);

      this.$root.$emit('usersUpdated');
      User.fetchCurrentUser();
      this.$bvToast.toast(
        this.unresolvableError
          ? `${this.users[this.selectedUser].name} was not deleted`
          : `${this.users[this.selectedUser].name} has been deleted`,
        {
          title: this.unresolvableError ? 'User Not Deleted' : 'User Deleted',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          append: false
        }
      );
    });

    this.closeDeleteModal();
  }

  public closeModal() {
    this.$root.$emit('bv::hide::modal', 'editUserData', '#btnHide');
  }

  public closeDeleteModal() {
    this.$root.$emit('bv::hide::modal', 'deleteUserModal', '#btnHide');
  }
}
