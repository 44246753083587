
import { isPage } from '@/utils/PageUtils';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class ScrollableContainer extends Vue {
  public maxHeight = `max-height:calc(100vh - 553px);`;

  mounted() {
    setTimeout(() => {
      this.getContainerPosition();
    }, 500);
  }

  created() {
    this.$root.$on('updateListSize', () => {
      setTimeout(() => {
        this.getContainerPosition();
      }, 300);
    });
    this.$root.$on('offsetChanged', this.scrollToTop);
    window.addEventListener('resize', this.getContainerPosition);
    document.addEventListener('mouseup', this.getContainerPosition);
  }
  destroyed() {
    window.removeEventListener('resize', this.getContainerPosition);
    document.removeEventListener('mouseup', this.getContainerPosition);
  }

  public getContainerPosition() {
    let topPosition = 553;
    if (this.$refs['scrollableContainer'] as HTMLElement) {
      topPosition = (this.$refs[
        'scrollableContainer'
      ] as HTMLElement).getBoundingClientRect().top;
    }
    this.maxHeight = `max-height:calc(100vh - ${topPosition}px);`;
  }

  public scrollToTop() {
    if (this.$refs['scrollableContainer'] as HTMLElement) {
      (this.$refs['scrollableContainer'] as HTMLElement).scrollTo(0, 0);
    }
  }
}
