
import FilterDropdown from '@/components/filters/FilterDropdown.vue';
import UsersList from '@/components/list/UsersList.vue';
import TableSearchbar from '@/components/table/TableSearchbar.vue';
import BoundingBox from '@/services/api/models/BoundingBox';
import CurrentUser from '@/store/models/CurrentUser';
import { Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import User from '@/store/modules/User';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import router from '@/router';
import DraggablePanel from '@/components/buttons/DraggablePanel.vue';

@Component({
  components: {
    UsersList,
    TableSearchbar,
    FilterDropdown,
    DraggablePanel
  },
  computed: {
    allProducts: () => ProductStore.currentProducts ?? [],
    ...mapGetters('user', {
      currentUser: 'currentUser',
      token: 'token'
    })
  }
})
export default class UserManagement extends mixins(AppName, ConstantsMixin) {
  @Prop() isTable!: boolean;
  @Prop() width!: number;

  public boundingBox: BoundingBox = {
    northEast: { lat: -32.41120443364559, lon: 180 },
    southWest: { lat: -48.93237346190712, lon: 140.93683593750006 }
  };
  public collapseMap = false;
  // public enableClustering = false;
  public users: CurrentUser[] = [];
  public vats: Product[] = [];
  public sortBy = 'supplierNumber';
  public searchTerm = '';
  private firstLoad = true;
  private selectedProductId: number | null = null;
  public entityState: Product | null = null; // Lets type this properly as entity current state.
  public increasing = true;
  public loading = true;
  public selected = false;
  public sidebarPos = 0;
  public vatId = -1;
  public mPos: any;
  public contentWidth = 1920;
  public mapHeight = 500;
  public prevMapHeight = 500;
  // public prevZoom = 6;
  public prevSelectedIndex = -1;
  // public ignoreBoundingBox = false;
  // public ignoreClustering = false;
  // public markerClusterer!: MarkerClusterer;
  public reloadMarkers = false;
  // Move type def somewhere else probably.
  public role = '';
  public isFilterDropVisible = false;

  mounted() {
    this.loading = true;
    this.fetchUsers();
    this.whatsthewidth();
    window.addEventListener('resize', this.whatsthewidth);

    this.$root.$on('bv::dropdown::show', (bvEvent: any) => {
      if (bvEvent.componentId === 'roleDrop') {
        this.isFilterDropVisible = true;
      }
    });
    this.$root.$on('bv::dropdown::hide', (bvEvent: any) => {
      if (bvEvent.componentId === 'roleDrop') {
        this.isFilterDropVisible = false;
      }
      if (this.isFilterDropVisible) {
        bvEvent.preventDefault();
      }
    });
    this.loading = false;
  }

  created() {
    this.$root.$on('usersUpdated', () => {
      this.loading = true;
      this.fetchUsers();
      this.loading = false;
    });

    this.$root.$on('orgIndexChanged', () => {
      this.loading = false;
    });
  }

  destroyed() {
    window.removeEventListener('resize', this.whatsthewidth);
  }

  public addNewUser() {
    router.push(`/${this.getAppName(false)}-settings/add-user`);
  }

  public whatsthewidth() {
    const width = document.getElementById('mainContent')?.offsetWidth;
    if (width) {
      this.contentWidth = width;
    }
  }

  private fetchUsers(): void {
    ProductStore.fetchUsers({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0
    }).then(async users => {
      this.users = users;
      this.filterUsers();
    });
  }

  public clearFilters(): void {
    this.role = '';
  }

  public search(searchTerm: string): void {
    this.searchTerm = searchTerm.toString();
    this.filterUsers();
  }

  public filterUsers(): void {
    // filter on search term
    let filtered = ProductStore.currentUsers;
    if (filtered) {
      filtered = filtered.filter(user => {
        return (
          user.name?.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1
        );
      });

      if (this.role != '') {
        filtered = filtered?.filter(
          product => (product as CurrentUser).role == this.role
        );
      }
      this.users = filtered;
    }
  }

  public updateRoleFilter(newType: string) {
    if (newType == this.role) {
      this.role = '';
    } else {
      this.role = newType;
    }
    this.filterUsers();
  }
}
